* {
    margin: 0;
    padding: 0;
}

html, body {
    height: 100%;
}

footer {
    position: fixed;
    padding: 5px 15px 5px 15px;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #5a6268;
    text-align: center;
    color: white;
    font-size: smaller;
}

    footer a, footer a:visited {
        text-decoration: underline;
        color: white;
    }

    footer a:hover {
        text-decoration: none;
    }

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.btn-success.disabled, .btn-success:disabled {
    cursor: not-allowed;
}

.clickableHelp {
    cursor: help;
}

.clickableDefault {
    cursor: pointer;
}

/* Popovers */

.bs-popover.bottom .arrow:after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1.5px;
    border-bottom-color: #4A4A4A;
}

.bs-popover.bottom {
    border-top-color: #4A4A4A;
}

.popover-header {
    background-color: #4A4A4A;
    color: #FFF;
}

/**********/
